<template>
	<div class="app-main__inner">
		<div class="container2 mb-3">
			<div class="flex justify-between align-items-center mb-3">
				<div>
					<div class="flex justify-between align-items-center space-x-3 mb-3">
						<span class="text-sm md:text-2xl font-semibold text-gray-900">{{ $t('manageUser') }}</span>
					</div>
				</div>
				<div
					class="flex justify-between align-items-center border rounded-md bg-green-500 text-xs md:text-xl px-2">
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none"
							 viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
							  d="M12 6v6m0 0v6m0-6h6m-6 0H6"/>
                        </svg>
                    </span>
					<span @click="openAddUsersModal"
						  class="text-xs h-10 text-white flex justify-center align-items-center cursor-pointer px-3 py-2 rounded-l-md">
                        {{ $t('addUser') }}
                    </span>
				</div>
			</div>


			<div class="row">
				<div class="col-md-12 mt-3">
					<div class="table-responsive rounded-lg bg-white border">
						<table v-loading='loading' style="width: 100%;" id="exampledestroy"
							   class="table table-bordered table-hover">
							<thead>
							<tr>
								<th class="text-xs">
									<div class="d-flex justify-content-between align-items-center">{{ $t('firstName') }}<span
										class="flex flex-col"><i @click="sortUserFirstnameAsc"
																 class="lnr-chevron-up cursor-pointer small"></i><i
										@click="sortUserFirstnameDsc" class="lnr-chevron-down cursor-pointer small"></i></span>
									</div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between align-items-center">{{
											$t('lastName')
										}}<span class="flex flex-col"><i @click="sortUserLastnameAsc"
																		 class="lnr-chevron-up cursor-pointer small"></i><i
											@click="sortUserLastnameDsc"
											class="lnr-chevron-down cursor-pointer small"></i></span></div>
								</th>
								<th class="text-xs">
									<div class="d-flex justify-content-between align-items-center">
										{{ $t('role') }}<span class="flex flex-col"><i @click="sortUserRoleAsc"
																					   class="lnr-chevron-up cursor-pointer small"></i><i
										@click="sortUserRoleDsc"
										class="lnr-chevron-down cursor-pointer small"></i></span></div>
								</th>
								<th class="text-xs">Status</th>
								<th class="text-xs">{{ $t('actions') }}</th>
							</tr>
							</thead>
							<tbody v-for="user in GET_SHOP_USERS.results" :key="user.id">
							<tr>
								<td>
									<span v-if="user.first_name">{{ user.first_name }}</span>
									<span v-else>---</span>
								</td>
								<td>
									<span v-if="user.last_name">{{ user.last_name }}</span>
									<span v-else>---</span>
								</td>
								<td><span v-if="user.user !== null">{{ $t(user.user.groups[0].name) }}</span><span
									v-else>---</span></td>
								<td>
									<span class="px-4 py-1 bg-green-500 text-white rounded-sm"
										  v-if="user.invitation_status === 'Completed'">{{ $t('completed') }}</span>
									<span class="px-4 py-1 bg-yellow-500 text-white rounded-sm"
										  v-if="user.invitation_status === 'Pending'">{{ $t('pending') }}</span>
								</td>
								<td>
									<div class="flex justify-center space-x-2">
                                            <span
												v-if="user.user !== null && user.user.groups[0].name !== 'EndUserCustomer'"
												@click="deleteEmployee(user)"
												class="flex justify-center align-items-center h-7 w-7 rounded-full bg-red-600 cursor-pointer">
                                                <img src="./../../assets/images/icons/delete.png" alt="">
                                            </span>
										<span v-if="user.user === null" @click="deleteEmployee(user)"
											  class="flex justify-center align-items-center h-7 w-7 rounded-full bg-red-600 cursor-pointer">
                                                <img src="./../../assets/images/icons/delete.png" alt="">
                                            </span>
										<span @click="updateUsers(user)"
											  class="flex justify-center align-items-center h-7 w-7 rounded-full bg-blue-900 cursor-pointer">
                                                <img src="./../../assets/images/icons/edit.png" alt="">
                                            </span>
									</div>
								</td>
							</tr>
							</tbody>
						</table>
					</div>
					<div class="flex flex-col md:flex-row justify-between mb-4">
                        <span class="flex justify-between align-items-center space-x-5 mt-4">
                            <span class="text-base">{{ $t('show') }}</span>
                            <span>
                                <select v-model="limit" class="form-select" @change="shopResultNumber">
                                    <option value="10" selected>10</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                    <option value="200">200</option>
                                </select>
                            </span>
                        </span>
						<div class="pagination-block"><!--GET_ARTICLES.total_pages--->
							<paginate
								:page-count="total_pages"
								:click-handler="changePageNumber"
								prev-text="<"
								next-text=">"
								:container-class="'pagination space-x-2'">
							</paginate>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from 'vuex'
// import bus from './../../bus'
import Paginate from 'vuejs-paginate'

export default {
	name: 'users',
	components: {
		paginate: Paginate,
	},
	data() {
		return {
			firstname: '',
			lastname: '',
			email: '',
			addingUsers: false,
			Users: [],
			loading: true,
			company_id: '',
			limit: 50,
			total_pages: 1,
		}
	},
	computed: {
		...mapGetters({
			GET_SHOP_USERS: 'customer/GET_SHOP_USERS'
		})
	},
	mounted() {
		const {company_id} = this.$route.params
		this.company_id = company_id

		if (this.company_id === 'invalid_company') {
			this.$services.helpers.notification(this.$t('no_company_text'), 'error', this)
		} else {
			this.getShopUsers()
		}
		window.Bus.$on('fetch-users', _ => {
			this.getShopUsers()
		})
	},
	methods: {
		openAddUsersModal() {
			window.Bus.$emit('add-buyer-account')
		},
		addUsers() {
			if (this.firstname === '' || this.lastname === '' || this.email === '') {
				this.$services.helpers.notification(this.$t('fieldRequired'), 'error', this)
			} else if (this.email.length > 50 || this.firstname.length > 50 || this.lastname.length > 50) {
				this.$services.helpers.notification(this.$t('fieldValue'), 'error', this)
			} else if (!this.$services.helpers.validateEmailAddress(this.email)) {
				this.$services.helpers.notification(this.$t('invalidEmail'), 'error', this)
			} else {
				this.addingUsers = true
				const payload = {
					email: this.email,
					first_name: this.firstname,
					last_name: this.lastname,
					user_type: 'Employee'
				}
				this.$store.dispatch('customer/addUsers', payload)
					.then(_ => {
						this.addingUsers = false
						this.clearField()
						this.$services.helpers.notification(this.$t('invitedUserSuccess'), 'success', this)
					}).catch((err) => {
					this.addingUsers = false
					if (err.response.status === 401) {
						this.$store.commit('auth/CLEAR_AUTH_CUS', null)
						window.Bus.$emit('sign-out')
					} else {
						this.$services.helpers.notification(err.response.data.message, 'success', this)
					}
				})
			}
		},
		deleteEmployee(data) {
			window.Bus.$emit('delete-user', data)
		},
		updateUsers(data) {
			window.Bus.$emit('update-users', data)
		},
		getShopUsers() {
			this.loading = true
			this.$store.dispatch('customer/getShopUsers', {data: `?limit=${this.limit}&company_id=${this.company_id}`})
				.then(_ => {
					this.loading = false
					if (this.GET_SHOP_USERS.count > this.limit) {
						this.total_pages = Math.ceil(this.GET_SHOP_USERS.count / this.limit)
					} else {
						this.total_pages = 1
					}
				}).catch((err) => {
				this.loading = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_CUS', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		shopResultNumber() {
			this.$store.dispatch('customer/getShopUsers', {data: `?limit=${this.limit}&company_id=${this.company_id}`})
				.then(_ => {
					this.loading = false
					if (this.GET_SHOP_USERS.count > this.limit) {
						this.total_pages = Math.ceil(this.GET_SHOP_USERS.count / this.limit)
					} else {
						this.total_pages = 1
					}
				}).catch((err) => {
				this.loading = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_CUS', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		changePageNumber(number) {
			this.$store.dispatch('customer/getShopUsers', {data: `?limit=${this.limit}&company_id=${this.company_id}&page=${number}`})
				.then(_ => {
					this.loading = false
					if (this.GET_SHOP_USERS.count > this.limit) {
						this.total_pages = Math.ceil(this.GET_SHOP_USERS.count / this.limit)
					} else {
						this.total_pages = 1
					}
				}).catch((err) => {
				this.loading = false
				if (err.response.status === 401) {
					this.$store.commit('auth/CLEAR_AUTH_CUS', null)
					window.Bus.$emit('sign-out')
				}
			})
		},
		sortUserFirstnameAsc() {
			this.$store.commit('customer/SORT_SHOP_USERS_FIRSTNAME_ASC')
		},
		sortUserFirstnameDsc() {
			this.$store.commit('customer/SORT_SHOP_USERS_FIRSTNAME_DSC')
		},
		sortUserLastnameAsc() {
			this.$store.commit('customer/SORT_SHOP_USERS_LASTNAME_ASC')
		},
		sortUserLastnameDsc() {
			this.$store.commit('customer/SORT_SHOP_USERS_LASTNAME_DSC')
		},
		sortUserRoleAsc() {
			this.$store.commit('customer/SORT_SHOP_USERS_ROLL_ASC')
		},
		sortUserRoleDsc() {
			this.$store.commit('customer/SORT_SHOP_USERS_ROLL_DSC')
		},
		clearField() {
			this.email = ''
			this.firstname = ''
			this.lastname = ''
		}
	}
}
</script>

<style lang="scss" scoped>
@import "./../../assets/variables.scss";

.form-control {
	border: 1px solid $form-border !important;
	height: unset !important;
	padding: .175rem .75rem !important;
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.btn-border-all {
	border: 1px solid $theme-secondary-color !important;
	color: $theme-secondary-color !important;
}

.color-red {
	color: red;
}


.form-control {
	border: 1px solid $form-border !important;
	height: unset !important;
	padding: .175rem .75rem !important;
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.btn-border-all {
	border: 1px solid $theme-secondary-color !important;
	color: $theme-secondary-color !important;
}

.color-red {
	color: red;
}

.row-ui {
	border-bottom: 1px solid #ddd;
	margin-left: 0;
	padding: 4px 0;
	font-size: 15px;
}

.table tbody tr > td {
	font-size: 13px !important;
	color: #6F6F6F !important;
	padding: 0.6rem 0.55rem !important;
}

.custom-btn-pay {
	background-color: #F167CA !important;
	border-color: #F167CA !important;
	font-weight: 800;
}

.custom-card-section {
	box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
	border-width: 0;
	transition: all .2s;
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(26, 54, 126, 0.125);
}

.custom-save-btn {
	background-color: #2CDCA7 !important;
	border-color: #2CDCA7 !important;
	font-weight: 800;
}

.override-form-field {
	border: 0;
	border-bottom: 2px solid rgb(64, 138, 235);
	box-shadow: none;
	background-color: #fff !important;
	border-radius: unset !important;
}

.col-form-label {
	padding-top: 1rem !important;
	// font-size: 14px !important;
}

.form-group {
	margin-bottom: 0.4rem !important;
}

.form-input {
	font-size: 0.8rem !important;
	padding: 0.2rem 0.75rem !important;
}

.col-form-label {
	padding-bottom: unset !important;
	padding-top: 0.3rem !important;
}

.app-main .app-main__inner {
	padding-top: 20px !important;
}

.table td {
	padding: 0.7rem 0.55rem !important;
}

.form-select {
	// border: 1px solid $form-border !important;
	height: unset !important;
	padding: .175rem 1.5rem !important;
}

.form-control {
	border: 1px solid $form-border !important;
	height: unset !important;
	padding: .175rem .75rem !important;
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}

.btn-border-all {
	border: 1px solid $theme-secondary-color !important;
	color: $theme-secondary-color !important;
}

.table tbody tr > td {
	font-size: 13px !important;
	color: #6F6F6F !important;
	padding: 0.6rem 0.55rem !important;
}
</style>
